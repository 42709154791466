import React, { memo } from 'react';

import { Handle } from 'react-flow-renderer';
import { Box, Text } from '@chakra-ui/react';

export default memo(({ id, data, isConnectable, ...rest }) => {
  const height =
    data.height || 10 * (Math.max(data.inputCount, data.outputCount, 2) + 1);
  return (
    <Box
      overflow='hidden'
      width={data.width ? `${data.width}px` : null}
      px={3}
      height={`${height}px`}
      onContextMenu={(e) => {
        e.preventDefault();
        data.openModal(id);
      }}
      style={{
        color: data.color || '#333333',
        background: data.backgroundColor || '#eeeeee',
        fontSize: '12px',
        border: '1px solid #555',
        borderRadius: '5px',
        textAlign: 'center',
      }}
    >
      {Array(data.inputCount)
        .fill(null)
        .map((a, i) => (
          <Handle
            key={`left${i}`}
            id={`left${i}`}
            // type='source'
            position='left'
            style={{
              background: '#555',
              top: `${(height / (data.inputCount + 1)) * (i + 1)}px`,
            }}
            onConnect={(params) => console.log('handle onConnect', params)}
            isConnectable={isConnectable}
          />
        ))}
      <Text fontSize='lg'>{data.customName}</Text>
      {Array(data.outputCount)
        .fill(null)
        .map((a, i) => (
          <Handle
            key={`right${i}`}
            id={`right${i}`}
            // type='source'
            position='right'
            style={{
              background: '#555',
              top: `${(height / (data.outputCount + 1)) * (i + 1)}px`,
            }}
            onConnect={(params) => console.log('handle onConnect', params)}
            isConnectable={isConnectable}
          />
        ))}
    </Box>
  );
});
