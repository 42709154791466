import React, { memo, useState } from 'react';

import { Handle } from 'react-flow-renderer';
import { Box, Text, Center } from '@chakra-ui/react';

export default memo(({ id, data, isConnectable, ...rest }) => {
  const [hover, setHover] = useState(false);
  return (
    <Box
      px={3}
      py={3}
      height={'20px'}
      onContextMenu={(e) => {
        e.preventDefault();
        data.openModal(id);
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        background: hover ? 'rgba(50, 150, 0, 0.5)' : 'rgba(0, 0, 0, 0)',
        fontSize: '12px',
        border: '1px solid #555',
        borderColor: hover ? null : 'rgba(0, 0, 0, 0)',
        borderRadius: '10px',
        textAlign: 'center',
      }}
    >
      <Handle
        id='point'
        style={{
          borderColor: hover ? null : 'rgba(0, 0, 0, 0)',
          background: hover ? '#555' : '#555',
          width: hover ? '6px' : '3px',
          height: hover ? '6px' : '3px',
          top: '8px',
        }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
    </Box>
  );
});
