import './App.css';

import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import {
  ChakraProvider,
  Container,
  Spinner,
  Center,
  Text,
  Heading,
  List,
  ListItem,
  Box,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactFlowProvider } from 'react-flow-renderer';

import Utils from './Utils';
import Map from './Map';
import TopBar from './TopBar';

const apiUriBase = Utils.apiUriBase;

function MapsList() {
  const [maps, setMaps] = useState([]);

  const getMaps = async () => {
    const mapsRes = await fetch(apiUriBase + '/map/');
    const maps = await mapsRes.json();
    setMaps(maps);
  };
  useEffect(() => {
    if (maps.length === 0) {
      getMaps();
    }
  }, [maps]);
  return (
    <Container maxW='container.lg'>
      <Heading>Мапи:</Heading>
      <List>
        {maps.length ? (
          maps.map((map) => (
            <ListItem key={map.Name}>
              <Link to={`/map/${map.id}`}>
                <Text fontSize='lg'>{map.Name}</Text>
              </Link>
            </ListItem>
          ))
        ) : (
          <Center>
            <Spinner />
          </Center>
        )}
        <ListItem key='list-item-offline-map'>
          <Link to={`/map/offline`}>
            <Text fontSize='lg'>Тест (працює offline)</Text>
          </Link>
        </ListItem>
      </List>
    </Container>
  );
}

function App() {
  const [map, setMap] = useState(null);
  return (
    <ChakraProvider
      style={{
        flexDirection: 'column',
        display: 'flex',
        flexGrow: 1,
        height: '100%',
      }}
    >
      <BrowserRouter>
        <Flex className='App' flexDirection='column' h='100vh'>
          <TopBar map={map} />
          <Routes>
            <Route path='/' element={<MapsList />} />
            <Route
              path='/map/:mapId'
              element={
                <ReactFlowProvider>
                  <Map setMap={setMap} />
                </ReactFlowProvider>
              }
            />
          </Routes>
        </Flex>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
