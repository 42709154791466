import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useColorModeValue,
  Link,
  Text,
  Stack,
  Skeleton,
  useToast,
  Center,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tooltip,
  RadioGroup,
  Radio,
  VStack,
} from '@chakra-ui/react';

import { FiHome, FiServer, FiPhone, FiPackage } from 'react-icons/fi';
import { FaHubspot } from 'react-icons/fa';

import Utils from './Utils';

const LinkItems = [
  {
    name: 'Точка',
    icon: FaHubspot,
    type: 'point',
    inputCount: 1,
  },
  {
    name: 'Телефон',
    icon: FiPhone,
    type: 'phone',
    inputCount: 1,
    outputCount: 0,
  },
  {
    name: 'АТС',
    icon: FiServer,
    type: 'phoneSwitch',
    inputCount: 0,
    outputCount: 10,
  },
  // { name: 'Будівля', icon: FiHome, type: 'building' },
];

const onDragStart = (event, nodeType, data = {}) => {
  switch (nodeType) {
    case 'point':
      event.dataTransfer.setData('application/reactflow', 'point');
      break;
    case 'custom':
      event.dataTransfer.setData('application/reactflow', 'custom');
      break;
    default:
      event.dataTransfer.setData('application/reactflow', 'custom');
      const itemType = LinkItems.find((item) => item.type === nodeType);
      data.customName = itemType.name;
      data.inputCount = itemType.inputCount;
      data.outputCount = itemType.outputCount;
      break;
  }
  event.dataTransfer.setData('application/reactflowData', JSON.stringify(data));
  event.dataTransfer.effectAllowed = 'move';
};

export default function Sidebar(props) {
  return (
    <Box bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
        overflow='auto'
        mapName={props.mapData ? props.mapData.Name : ''}
        mapId={props.mapData ? props.mapData.id : 0}
        rfInstance={props.rfInstance}
        setImageScale={props.setImageScale}
        connectionParams={props.connectionParams}
        setConnectionParams={props.setConnectionParams}
      />
    </Box>
  );
}

const SidebarContent = ({
  onClose,
  mapName,
  mapId,
  imageScale,
  setImageScale,
  rfInstance,
  connectionParams,
  setConnectionParams,
  ...rest
}) => {
  const [inputCount, setInputCount] = useState('');
  const [outputCount, setOutputCount] = useState('');
  const [customName, setCustomName] = useState('');

  const toast = useToast();

  const [sliderValue, setSliderValue] = React.useState(imageScale || 1000);
  useEffect(() => {
    setImageScale(sliderValue);
  }, [setImageScale, sliderValue]);
  const [showTooltip, setShowTooltip] = React.useState(false);

  let timer = useRef();

  return (
    <Box
      bg={useColorModeValue('white', 'gray.900')}
      borderRight='1px'
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={250}
      h='full'
      position='relative'
      {...rest}
    >
      {/* <Flex h='60' alignItems='center' mx='8' justifyContent='space-between'>
        {mapName ? (
          <Text fontSize='2xl' fontFamily='monospace' fontWeight='bold'>
            {mapName}
          </Text>
        ) : (
          <Stack width='100%'>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
          </Stack>
        )}
      </Flex> */}
      {LinkItems.map((link) => (
        <Box
          style={{ cursor: 'grab' }}
          my='4'
          mx='8'
          draggable={true}
          key={link.name}
          type={link.type}
          onClick={(event) => console.log(event)}
          onDragStart={(event) => {
            onDragStart(event, link.type, {});
          }}
        >
          <Icon mr='4' fontSize='16' as={link.icon} />
          {link.name}
        </Box>
      ))}
      <hr />
      <Box mx='8' my='4'>
        <Flex
          my='2'
          align='center'
          borderRadius='lg'
          role='group'
          cursor='grab'
          draggable
          className='dndnode input'
          onDragStart={(event) => {
            if ((!inputCount && !outputCount) || !customName) {
              const errors = [];
              if (!inputCount && !outputCount)
                errors.push('не вказана кількість портів');
              if (!customName) errors.push('не вказана назва');
              toast({
                title: "Довільний об'єкт не додано",
                description: errors.join('\n'),
                status: 'error',
                duration: 3500,
                isClosable: true,
                containerStyle: {
                  whiteSpace: 'pre-wrap',
                },
              });
            } else {
              onDragStart(event, 'custom', {
                customName,
                inputCount,
                outputCount,
              });
            }
          }}
        >
          <Icon mr='4' fontSize='16' as={FiPackage} />
          <Text>Довільний об`єкт</Text>
        </Flex>
        <Input
          my='1'
          placeholder='Назва'
          value={customName}
          onChange={(event) => setCustomName(event.target.value)}
        />
        <NumberInput
          my='1'
          min={1}
          max={200}
          value={inputCount}
          onChange={(value) => {
            if (value === '') setInputCount('');
            else {
              const intValue = parseInt(value);
              if (!isNaN(intValue)) setInputCount(intValue);
            }
          }}
        >
          <NumberInputField placeholder='Портів зліва' />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <NumberInput
          my='1'
          min={1}
          max={200}
          value={outputCount}
          onChange={(value) => {
            if (value === '') setOutputCount('');
            else {
              const intValue = parseInt(value);
              if (!isNaN(intValue)) setOutputCount(intValue);
            }
          }}
        >
          <NumberInputField placeholder='Портів справа' />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Box>
      <hr />
      <Box mx='8' my='4'>
        <Text mb={2}>Тип з'єднання:</Text>
        <RadioGroup
          onChange={(value) =>
            setConnectionParams({ ...connectionParams, type: value })
          }
          value={connectionParams.type}
        >
          <VStack align='left'>
            <Radio value='bezier'>Крива Безьє</Radio>
            <Radio value='step'>Прямий кут</Radio>
            <Radio value='straight'>Пряма</Radio>
          </VStack>
        </RadioGroup>
        <Flex py={2}>
          <Text pr={2}>Колір:</Text>
          <input
            type='color'
            id='pathColor'
            onChange={(e) => {
              const newColor = e.target.value;
              clearTimeout(timer);
              timer = setTimeout((e) => {
                setConnectionParams({
                  ...connectionParams,
                  color: newColor,
                });
              }, 20);
            }}
            value={connectionParams.color}
          />
        </Flex>
      </Box>
      <hr />
      <Box mx='8' my='4'>
        <Text>Масштаб зображення</Text>
        <Slider
          id='slider'
          defaultValue={1000}
          min={100}
          max={10000}
          colorScheme='teal'
          onChange={(v) => setSliderValue(v)}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <Tooltip
            hasArrow
            bg='teal.500'
            color='white'
            placement='top'
            isOpen={showTooltip}
            label={`${sliderValue}`}
          >
            <SliderThumb />
          </Tooltip>
        </Slider>
      </Box>
      <hr />
      <Center py={4}>
        <Stack direction='column' gap={2}>
          {mapId !== 'offline' && (
            <Button
              onClick={() => {
                fetch(`${Utils.apiUriBase}/map/${mapId}/`, {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(rfInstance.toObject()),
                });
              }}
            >
              Зберігти
            </Button>
          )}
          <Button
            onClick={() => {
              console.log(rfInstance.toObject());
            }}
          >
            В консоль
          </Button>
        </Stack>
      </Center>
    </Box>
  );
};
