import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ReactFlow, {
  addEdge,
  useNodesState,
  useEdgesState,
  useUpdateNodeInternals,
  Controls,
  Background,
  MiniMap,
} from 'react-flow-renderer';
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Input,
  SelectField,
  Flex,
  propNames,
  Stack,
  Center,
  Heading,
  Spinner,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';

import { VscError } from 'react-icons/vsc';
import { IoMdRefresh } from 'react-icons/io';

import Utils from './Utils';
import OfflineMap from './OfflineMap';

import Sidebar from './Sidebar';
import CustomNode from './CustomNode';
import ImageNode from './ImageNode';
import PointNode from './PointNode';

const nodeTypes = {
  custom: CustomNode,
  image: ImageNode,
  point: PointNode,
};

const getId = () => `node_${Date.now()}`;

const Map = (props) => {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const [editElementId, setEditElementId] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const openEditModal = useCallback(
    (id) => {
      onOpen();
      setEditElementId(id);
    },
    [onOpen],
  );

  const [imageScale, setImageScale] = useState(1000);
  useEffect(() => {
    const newNodes = nodes.map((node) => {
      if (node.id === 'background')
        node.data = {
          ...node.data,
          scale: imageScale,
        };
      return node;
    });
    setNodes(newNodes);
  }, [imageScale]);

  const [connectionParams, setConnectionParams] = useState({
    type: 'bezier',
    color: '#b1b1b7',
    width: 1,
  });

  const updateNodeInternals = useUpdateNodeInternals();

  const params = useParams();
  const mapId = params.mapId;
  const [mapData, setMapData] = useState(null);
  const [mapLoadStatus, setMapLoadStatus] = useState({
    status: 'loading',
    message: '',
  });
  const getMapData = useCallback(async () => {
    let mapData = {};

    if (mapId !== 'offline') {
      try {
        const mapDataRes = await fetch(`${Utils.apiUriBase}/map/${mapId}`);
        console.log(mapDataRes);
        if (mapDataRes.ok) {
          setMapLoadStatus({ status: 'done' });
          mapData = await mapDataRes.json();
        } else {
          throw new Error(`${mapDataRes.status}: ${mapDataRes.statusText}`);
        }
      } catch (e) {
        console.error(e);
        setMapLoadStatus({ status: 'error', message: e.message || 'a' });
        return;
      }
    } else {
      setMapLoadStatus({ status: 'done' });
      mapData = {
        id: 'offline',
        Name: 'Offline Test',
        Image: 'https://picsum.photos/1000/500?grayscale',
        Data: OfflineMap,
      };
    }
    setMapData(mapData);
    props.setMap(mapData.Name);
    let fetchedNodes = mapData.Data.nodes;
    const fetchedEdges = mapData.Data.edges;
    if (mapData.Image) {
      if (fetchedNodes.find((node) => node.id === 'background')) {
        const imageNode = fetchedNodes.find((node) => node.id === 'background');
        imageNode.data.imgSrc = mapData.Image.startsWith('/media')
          ? `${Utils.imgUriBase}${mapData.Image}`
          : mapData.Image;
        if (imageNode.data.scale) setImageScale(imageNode.data.scale);
      } else {
        fetchedNodes = [
          ...fetchedNodes,
          {
            id: 'background',
            type: 'image',
            data: { imgSrc: mapData.Image, scale: 1000 },
            draggable: false,
            selectable: false,
            position: { x: 0, y: 0 },
          },
        ];
      }
    } else {
      fetchedNodes = fetchedNodes.filter((node) => node.id !== 'background');
    }
    setNodes(
      fetchedNodes.map((node) => {
        delete node.handleBounds;
        delete node.selected;
        node.data.openModal = openEditModal;
        return node;
      }),
    );
    setEdges(fetchedEdges);
  }, [mapId, openEditModal, setEdges, setNodes]);
  useEffect(() => {
    if (!mapData) {
      getMapData();
    }
  }, [mapData, getMapData]);

  const onConnect = useCallback(
    (params) => {
      params.type = connectionParams.type;
      params.style = { stroke: connectionParams.color };
      setEdges((eds) => addEdge(params, eds));
    },
    [connectionParams, setEdges],
  );
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);
  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const data = JSON.parse(
        event.dataTransfer.getData('application/reactflowData'),
      );

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const newNode = {
        id: getId(),
        type,
        position,
        data: { ...data, openModal: openEditModal },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance, openEditModal, setNodes],
  );
  return mapLoadStatus.status === 'done' ? (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Редагування елементу</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize='sm' pb={2}>
              id: {editElementId}
            </Text>
            {nodes.find((node) => node.id === editElementId)?.type ===
              'custom' && (
              <>
                <Text>Назва:</Text>
                <Input
                  id='editCustomName'
                  defaultValue={
                    nodes.find((node) => node.id === editElementId)?.data
                      ?.customName
                  }
                />
                <Flex direction='row' justifyContent='space-between' py={4}>
                  <Flex gap={2}>
                    <Text>Колір елементу: </Text>
                    <input
                      id='editCustomBackgroundColor'
                      type='color'
                      defaultValue={
                        nodes.find((node) => node.id === editElementId)?.data
                          ?.backgroundColor || '#eeeeee'
                      }
                    />
                  </Flex>
                  <Flex gap={2}>
                    <Text>Колір назви:</Text>
                    <input
                      id='editCustomColor'
                      type='color'
                      defaultValue={
                        nodes.find((node) => node.id === editElementId)?.data
                          ?.color || '#333333'
                      }
                    />
                  </Flex>
                </Flex>
                <Flex gap={2} alignItems='center'>
                  <Text>Ширина:</Text>
                  <NumberInput
                    id='editCustomWidth'
                    min={10}
                    defaultValue={
                      nodes.find((node) => node.id === editElementId)?.data
                        ?.width
                    }
                  >
                    <NumberInputField placeholder='авто' />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text>Висота:</Text>
                  <NumberInput
                    id='editCustomHeight'
                    min={10}
                    defaultValue={
                      nodes.find((node) => node.id === editElementId)?.data
                        ?.height
                    }
                  >
                    <NumberInputField placeholder='авто' />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Flex>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme='green'
              mr={3}
              onClick={() => {
                const editedNodes = nodes.map((node) => {
                  if (node.id === editElementId) {
                    node.data = {
                      ...node.data,
                      customName:
                        document.querySelector('#editCustomName').value,
                      color: document.querySelector('#editCustomColor').value,
                      backgroundColor: document.querySelector(
                        '#editCustomBackgroundColor',
                      ).value,
                      width: document.querySelector('#editCustomWidth').value,
                      height: document.querySelector('#editCustomHeight').value,
                    };
                  }
                  return node;
                });

                onClose();
                setNodes(editedNodes);
                updateNodeInternals(editElementId);
              }}
            >
              Зберігти
            </Button>
            {/* <Button variant='outline' mr={3} onClick={onClose}>
              Відміна
            </Button> */}
            <Button
              colorScheme='red'
              onClick={() => {
                setNodes(nodes.filter((node) => node.id !== editElementId));
                setEdges(
                  edges.filter(
                    (edge) =>
                      edge.source !== editElementId &&
                      edge.target !== editElementId,
                  ),
                );
                onClose();
              }}
            >
              Видалити
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex flexDirection='row' w='100%' minH={0} flexGrow={1}>
        <Sidebar
          mapData={mapData}
          rfInstance={reactFlowInstance}
          setRfInstance={setReactFlowInstance}
          setImageScale={setImageScale}
          connectionParams={connectionParams}
          setConnectionParams={setConnectionParams}
        />

        <div
          className='reactflow-wrapper'
          style={{
            flexGrow: 1,
          }}
          ref={reactFlowWrapper}
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            fitView
            fitViewOptions={{ padding: 0 }}
            nodeTypes={nodeTypes}
            connectionMode='loose'
          >
            <Controls />
            <Background />
            <MiniMap />
          </ReactFlow>
        </div>
      </Flex>
    </>
  ) : (
    <Center h='100%' flexDirection='column'>
      {mapLoadStatus.status === 'error' ? (
        <>
          <VscError fontSize={50} />
          <Heading as='h4' size='md' py={2}>
            Помилка завантаження мапи
          </Heading>
          <Text py={2}>{mapLoadStatus.message}</Text>
          <Button
            leftIcon={<IoMdRefresh />}
            colorScheme='red'
            variant='solid'
            onClick={() => {
              setMapLoadStatus({ status: 'loading', message: '' });
              getMapData();
            }}
          >
            Спробувати ще
          </Button>
        </>
      ) : (
        <>
          <Spinner size='xl' mb={4} />
          <Heading as='h4' size='md'>
            Мапа завантажується...
          </Heading>
        </>
      )}
    </Center>
  );
};

export default Map;
