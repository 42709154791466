import { Box, Button, Text, Stack, useColorModeValue } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';

import { RiMap2Fill } from 'react-icons/ri';

const TopBar = (props) => {
  let location = useLocation();
  return (
    <Box
      bg={useColorModeValue('white', 'gray.900')}
      borderBottom='1px'
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      px={4}
      py={1}
    >
      <Stack spacing={4} direction='row' align='center'>
        <Link to='/'>
          <Button colorScheme='teal' size='sm'>
            <RiMap2Fill />
            <Text ml={2}>До списку мап</Text>
          </Button>
        </Link>
        {location.pathname.startsWith('/map/') && (
          <Box>
            <Text fontWeight='bold'>{props.map}</Text>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default TopBar;
