import React, { memo } from 'react';

import { Box, Image } from '@chakra-ui/react';

export default memo(({ data }) => {
  return (
    <Box style={{ width: `${data.scale || 1000}px`, zIndex: -999 }}>
      {data.imgSrc ? (
        <Image
          src={data.imgSrc}
          alt='Фон'
          style={{ width: '100%', maxWidth: 'unset' }}
        />
      ) : null}
    </Box>
  );
});
