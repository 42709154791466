const OfflineMap = {
  nodes: [
    {
      id: 'background',
      type: 'image',
      draggable: false,
      selectable: false,
      position: {
        x: 0,
        y: 0,
      },
      data: {
        imgSrc: 'https://picsum.photos/1000/500?grayscale',
        scale: 1000,
      },
      z: 0,
      positionAbsolute: {
        x: 0,
        y: 0,
      },
    },
    {
      id: 'node_1648149074765',
      type: 'custom',
      position: {
        x: 182,
        y: 414,
      },
      data: {
        customName: 'Test 1',
        inputCount: 2,
        outputCount: 5,
        color: '#7300FF',
        backgroundColor: '#00D5FF',
      },
      z: 0,
    },
    {
      id: 'node_1648149083359',
      type: 'custom',
      position: {
        x: 300,
        y: 341.75,
      },
      data: {
        customName: 'Test 2',
        inputCount: 5,
        outputCount: 2,
        width: 100,
        height: 100,
      },
      z: 0,
    },
    {
      id: 'node_1648149093916',
      type: 'custom',
      position: {
        x: 414.5,
        y: 436.25,
      },
      data: {
        customName: 'Test 3',
        inputCount: 11,
        outputCount: 6,
      },
      z: 0,
    },
    {
      id: 'node_1648210632562',
      type: 'point',
      position: {
        x: 289.83365570599614,
        y: 558.2565280464216,
      },
      data: {
        label: 'point node',
      },
      z: 1000,
    },
  ],
  edges: [
    {
      source: 'node_1648149074765',
      sourceHandle: 'right0',
      target: 'node_1648149083359',
      targetHandle: 'left1',
      id: 'reactflow__edge-node_1648149074765right0-node_1648149083359left1',
    },
    {
      source: 'node_1648149083359',
      sourceHandle: 'right1',
      target: 'node_1648149093916',
      targetHandle: 'left0',
      id: 'reactflow__edge-node_1648149083359right1-node_1648149093916left0',
    },
    {
      source: 'node_1648210632562',
      sourceHandle: 'point',
      target: 'node_1648149093916',
      targetHandle: 'left7',
      type: 'straight',
      id: 'reactflow__edge-node_1648210632562point-node_1648149093916left7',
    },
    {
      source: 'node_1648149074765',
      sourceHandle: 'right4',
      target: 'node_1648210632562',
      targetHandle: 'point',
      type: 'straight',
      id: 'reactflow__edge-node_1648149074765right4-node_1648210632562point',
    },
    {
      source: 'node_1648149074765',
      sourceHandle: 'right1',
      target: 'node_1648149093916',
      targetHandle: 'left5',
      type: 'step',
      id: 'reactflow__edge-node_1648149074765right1-node_1648149093916left5',
      style: {
        stroke: 'red',
        // strokeWidth: 3,
      },
    },
  ],
};

export default OfflineMap;
